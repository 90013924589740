<template>
  <div>
    <b-card>
      <b-row class="">
        <b-col md="12" class="d-flex justify-content-end mb-1">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary" @click="addRules" v-if="$can('update', 'competency')"
          >
            添加规则
          </b-button>
        </b-col>
      </b-row>

      <!-- table -->
      <vxe-table
        ref="xTable"
        border
        :auto-resize="true"
        :align="allAlign"
        :data="tableData"
        highlight-current-row
        :loading="loading"
        header-row-class-name="false"
        :tree-config="{ children: 'children' }"
        :scroll-x="{ enabled: true }"
        :scroll-y="{ enabled: true }"
      >
        <vxe-table-column field="id" title="ID"  tooltip width="60"></vxe-table-column>
        <vxe-table-column field="name" tree-node title="权限名称" width="220"></vxe-table-column>
        <vxe-table-column field="type" title="规则类型" width="90" align="center">
          <template v-slot="{ row }">
            <div v-if="row.type === 'module'">模块</div>
            <div v-else-if="row.type === 'menu'">菜单</div>
            <div v-else-if="row.type === 'api'">接口</div>
            <div v-else>其他</div>
          </template>
        </vxe-table-column>
        <vxe-table-column field="subject" title="前端主题" min-width="250"></vxe-table-column>
        <vxe-table-column field="action" title="主题动作" width="90" align="center"></vxe-table-column>
        <vxe-table-column field="routeName" title="路由名称" min-width="250" tooltip="true"></vxe-table-column>
        <vxe-table-column field="apiKey" title="后端标志" min-width="300" tooltip="true"></vxe-table-column>
        <vxe-table-column field="sort" title="排序" align="center" width="70"></vxe-table-column>
        <vxe-table-column field="status" title="状态" width="80" align="center">
          <template v-slot="{ row }">
            <div v-if="$can('status', 'competency')">
              <b-form-checkbox @change="statusEvent(row.id)" :checked=" row.status == 1 ? true:false" class="custom-control-primary" name="check-button" switch />
            </div>
            <div v-else>无权限</div>
          </template>
        </vxe-table-column>

        <vxe-table-column field="actions" title="操作" align="center" width="100" fixed="right" class-name="vxe-table-padding">
          <template v-slot="{ row }">
            <div class="text-nowrap">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary" class="btn-icon" size="sm" @click="editEvent(row)"
              >
                <feather-icon icon="EditIcon" class="cursor-pointer text-body" size="16" />
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="flat-primary" class="btn-icon" size="sm" @click="delEvent(row.id)"
                >
                  <feather-icon icon="Trash2Icon" class="cursor-pointer text-body" size="16" />
              </b-button>
            </div>
          </template>
        </vxe-table-column>
      </vxe-table>
    </b-card>

    <!-- Rules Modal -->
    <rules-modal
      v-model="shallShowRulesModal"
      :formRules="formRules"
      :pidOptions="pidTreeOptions"
      @fetch-data="fetchData"
      @get-pid-tree-options="getPidTreeOptions"
    />
  </div>

</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { menuListApi, menuSelectTreeApi, menuDelApi, menuStatusApi } from '@/api/system/authority'
import { getParentIds } from '@/libs/array'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RulesModal from './RulesModal.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    RulesModal,
  },
  setup(props, context) {
    const { $swal, $can } = context.root
    // Use toast
    const toast = useToast()

    const dir = ref(false)

    const loading = ref(false)
    const allAlign = ref(null)
    const tableData = ref([])

    const direction = computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr'))

    const fetchData = () => {
      menuListApi()
        .then(res => { tableData.value = res.data })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取权限规则失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    onMounted(() => {
      if ($can('read', 'competency')) {
        fetchData()
      } else {
        toast({
          component: ToastificationContent,
          props: { title: '您没有获取权限规则列表的权限', icon: 'AlertTriangleIcon', variant: 'danger' },
        })
      }
    })

    const shallShowRulesModal = ref(false)
    const formRules = ref({
      id: 0,
      pid: [0],
      name: '',
      type: 'api',
      routeName: '',
      apiKey: '',
      status: 1,
      action: '',
      subject: '',
      sort: 0,
    })

    const pidTreeOptions = ref([])
    const getPidTreeOptions = () => {
      menuSelectTreeApi()
        .then(res => {
          res.data.unshift({ value: 0, label: '顶级模块' })
          pidTreeOptions.value = res.data
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取权限下拉列表失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }
    onMounted(() => {
      if ($can('extend', 'competency')) {
        getPidTreeOptions()
      } else {
        toast({
          component: ToastificationContent,
          props: { title: '您没有获权限规则下拉项的权限', icon: 'AlertTriangleIcon', variant: 'danger' },
        })
      }
    })

    const editEvent = row => {
      formRules.value = JSON.parse(JSON.stringify(row))
      formRules.value.pids = getParentIds(pidTreeOptions.value, row.id)
      shallShowRulesModal.value = true
    }

    const statusEvent = id => {
      menuStatusApi({id})
        .then(() => {
          fetchData()
          toast({
            component: ToastificationContent,
            props: { title: '权限状态更新成功', icon: 'CheckCircleIcon', variant: 'success' },
          })
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '权限状态更新失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    const delEvent = id => {
      $swal({
        title: '确定删除此权限吗?',
        text: '删除权限会同步删除此权限下的子权限,请知悉!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          menuDelApi({id})
            .then(() => {
              fetchData()
              toast({
                component: ToastificationContent,
                props: { title: '删除权限成功', icon: 'CheckCircleIcon', variant: 'success' },
              })
            })
            .catch(err => {
              toast({
                component: ToastificationContent,
                props: { title: '删除权限失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
              })
            })
        }
      })
    }

    const addRules = () => {
      formRules.value = {
        id: 0,
        pid: [0],
        name: '',
        routeName: '',
        type: 'api',
        apiKey: '',
        status: true,
        action: '',
        subject: '',
        sort: 0,
      }
      shallShowRulesModal.value = true
    }

    return {
      dir,
      loading,
      allAlign,
      tableData,
      direction,

      fetchData,
      delEvent,
      statusEvent,
      editEvent,
      addRules,

      shallShowRulesModal,
      formRules,
      pidTreeOptions,
      getPidTreeOptions,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
