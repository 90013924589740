<template>
  <!-- modal-class="modal-sticky" -->
  <!-- centered -->
  <b-modal
    id="rules-modal"
    :visible="shallShowRulesModal"
    title="权限规则"
    modal-class="modal-sticky"
    footer-class="d-flex justify-content-between"
    body-class="px-2"
    size="lg"
    no-fade
    hide-backdrop
    static
    @change="(val) => $emit('update:shall-show-rules-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title"> 权限规则 </h5>
      <div class="modal-actions">
        <feather-icon icon="MinusIcon" class="cursor-pointer" @click="$emit('update:shall-show-rules-modal', false)" />
        <feather-icon icon="XIcon" class="ml-1 cursor-pointer" @click="hideModal" />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <div>
        <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click="submitEvent">
          {{ formRulesData.id > 0 ? '修改' : '添加' }}
        </b-button>
      </div>

      <div>
        <b-button variant="outline-secondary" @click="resetEvent"> 重置 </b-button>
        <b-button variant="outline-secondary" class="ml-75 cursor-pointer" @click="hideModal"> 取消 </b-button>
      </div>
    </template>

    <div class="d-flex justify-content-between"></div>
    <b-form class="mb-1">
      <b-row>

        <!-- Field: type -->
        <b-col cols="12" md="12" lg="12">
          <b-form-group label="规则类型" label-for="type" label-cols="2">
            <b-form-radio-group id="type" class="col-form-label" v-model="formRulesData.type" :options="typeOptions" value="menu" />
          </b-form-group>
        </b-col>

        <!-- Field: name -->
        <b-col cols="12" md="6" lg="6">
          <b-form-group label="规则名称" label-for="name">
            <b-form-input id="name" v-model="formRulesData.name" />
          </b-form-group>
        </b-col>

        <!-- Field: pid -->
        <b-col cols="12" md="6" lg="6" v-if="$can('extend', 'competency')">
          <b-form-group label="父级分类" label-for="pid" >
            <Cascader :data="pidOptions" :change-on-select="true" v-model="formRulesData.pids" />
          </b-form-group>
        </b-col>

        <!-- Field: routeName -->
        <b-col cols="12" md="6" lg="6">
          <b-form-group label="路由标识" label-for="routeName">
            <b-form-input id="routeName" v-model="formRulesData.routeName" />
          </b-form-group>
        </b-col>

        <!-- Field: apiKey -->
        <b-col cols="12" md="6" lg="6">
          <b-form-group label="后端标识" label-for="apiKey">
            <b-form-input id="apiKey" v-model="formRulesData.apiKey" />
          </b-form-group>
        </b-col>

        <!-- Field: subject -->
        <b-col cols="12" md="6" lg="6">
          <b-form-group label="前端主题" label-for="subject">
            <b-form-input id="subject" v-model="formRulesData.subject" />
          </b-form-group>
        </b-col>
        <!-- Field: action -->
        <b-col cols="12" md="6" lg="6">
          <b-form-group label="主题动作" label-for="action">
            <b-form-input id="action" v-model="formRulesData.action" />
          </b-form-group>
        </b-col>

        <!-- Field: sort -->
        <b-col cols="12" md="6" lg="6">
          <b-form-group label="权限排序" label-for="sort">
            <b-form-input id="sort" type="number" number v-model="formRulesData.sort" />
          </b-form-group>
        </b-col>

        <!-- Field: status -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="权限状态" label-for="status" label-class="mb-1">
            <b-form-radio-group id="status" v-model="formRulesData.status" :options="statusOptions" :value="1" />
          </b-form-group>
        </b-col>

      </b-row>

    </b-form>

  </b-modal>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { ref, toRefs, watch } from '@vue/composition-api'
import { menuSaveApi } from '@/api/system/authority'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
  },
  model: {
    prop: 'shallShowRulesModal',
    event: 'update:shall-show-rules-modal',
  },
  props: {
    shallShowRulesModal: {
      type: Boolean,
      required: true,
    },
    formRules: {
      type: Object,
      required: true,
      default: null,
    },
    pidOptions: {
      type: Array,
      required: true,
      default: null,
    },
  },
  setup(propsValue, { emit }) {
    const props = toRefs(propsValue)

    const toast = useToast()

    const typeOptions = [
      { text: '菜单', value: 'menu' },
      { text: '接口', value: 'api' },
      { text: '模块', value: 'module' },
    ]
    const statusOptions = [{ text: '启用', value: true }, { text: '禁用', value: false }]

    const formRulesData = ref(JSON.parse(JSON.stringify(props.formRules.value)))

    const hideModal = () => { emit('update:shall-show-rules-modal', false) }
    const submitEvent = () => {
      formRulesData.value.pid = formRulesData.value.pids[formRulesData.value.pids.length - 1]
      menuSaveApi(formRulesData.value)
        .then(() => {
          emit('fetch-data')
          emit('get-pid-tree-options')
          hideModal()
          toast({
            component: ToastificationContent,
            props: { title: '权限状态更新成功', icon: 'CheckCircleIcon', variant: 'success' },
          })
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '权限状态更新失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }
    const resetEvent = () => {
      formRulesData.value = JSON.parse(JSON.stringify(props.formRules.value))
    }
    watch(props.formRules, () => { resetEvent() })
    watch(props.shallShowRulesModal, () => { resetEvent() })

    return {
      typeOptions,
      statusOptions,
      formRulesData,

      hideModal,
      submitEvent,
      resetEvent,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
